<template>
  <div class="position-relative">
    <strapi-image
      class="position-absolute top-0 start-0 w-100 h-100"
      :image="component.customComponent.backgroundImage"
      as-background
    />

    <div class="container py-12">
      <h2 class="fs-1 fw-bold mb-8 text-center">
        {{ component.customComponent.title }}
      </h2>
      <p class="fs-5 fw-bold mb-12 text-center">
        {{ component.customComponent.content }}
      </p>
      <div class="row gy-16 gy-md-0 justify-content-center">
        <strapi-button
          v-for="link in component.customComponent.buttons"
          :key="link.id"
          class="col-6 col-md-2"
          :component="link"
          :force-image-height="64"
          :force-image-width="64"
          :image-classes="'mx-auto d-block'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { StrapiCustomComponent } from '../../../apollo/types/types';
import StrapiButton from '../../strapi/StrapiButton.vue';

export default defineComponent({
  props: {
    component: {
      type: Object as PropType<StrapiCustomComponent>,
      required: true,
    },
  },
});
</script>
